import React from "react"

import PageBody from "../../components/page-body/pageBody"
import SEO from "../../components/seo"

import Banner from "../../components/banner/banner"
import bg from "../../images/banners/about-the-program.jpg"
import aboutTheProgram from "../../images/components/about-the-program-body.jpg"
import BorderTitle from "../../components/border-title/borderTitle"
import { aboutTheProgramPage } from "../../utils/text"

import "./the-program.scss"

var TheProgram = props => (
  <>
    <SEO title="Home" keywords={["gatsby", "application", "react"]} />
    <div className="the-program-page">
      <Banner
        src={bg}
        title={aboutTheProgramPage.banner.title}
        subTitle={aboutTheProgramPage.banner.subtitle}
        backgroundPosY="20%"
        height="27.5rem"
      />
      <PageBody className="page">
        <BorderTitle>{aboutTheProgramPage.aboutUs.title}</BorderTitle>
        <div className="text">
          <img src={aboutTheProgram} alt="" />
          {aboutTheProgramPage.aboutUs.body}
          
          {/* Original
          {aboutTheProgramPage.aboutUs.body.map(text => (
            <p key={text.substr(10)}>{text}</p>
          ))}
          */}
        </div>

        <div className="pad-top-5">
          <br></br>
          <BorderTitle>{aboutTheProgramPage.howToJoinTheProgram.title}</BorderTitle>
          <div className="text">
            {aboutTheProgramPage.howToJoinTheProgram.text}
          </div>
        </div>
      </PageBody>
    </div>
  </>
)

export default TheProgram
